/* Color Variables */
:root {
  --black: #333333;
  --blue: #686de0;
  --gray: #d3d3d3;
  --green: #6ab04c;
  --red: #dc3545;
  --white: #ffffff;
}
.components-popconfirm-demo-placement .ant-btn {
  margin-left: 0;
  margin-right: 8px;
  margin-bottom: 8px;
  width: 70px;
  text-align: center;
  padding: 0;
}
/* General Styles */
code {
  display: inline-block;
  padding: 0.25rem 0.5rem;
}

.pin-field-container {
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  justify-content: center;
  margin: 3rem 0;
}

.pin-field {
  border-top: 0;
  margin-left: 10px;
  border-right: none;
  border-left: none;
  font-size: 2rem;
  height: 4rem;
  outline: none;
  text-align: center;
  transition-duration: 250ms;
  transition-property: color, border, box-shadow, transform;
  width: 10rem;
}

.pin-field:focus {
  border-color: var(--blue);
  box-shadow: 0 0 0.25rem rgba(var(--blue), 0.5);
  outline: none;
}

.pin-field:invalid {
  animation: shake 5 linear 75ms;
  border-color: var(--red);
  box-shadow: 0 0 0.25rem rgba(var(--red), 0.5);
}

.pin-field.complete {
  border-color: var(--green);
  color: var(--green);
}

.pin-field.complete[disabled] {
  background: rgba(var(--green), 0.1);
  cursor: not-allowed;
  opacity: 0.5;
}

/* Adjacent Pin Field Styles */
.pin-field:focus + .pin-field {
  border-left-color: var(--blue);
}

.pin-field:invalid + .pin-field {
  border-left-color: var(--red);
}

.pin-field.complete + .pin-field {
  border-left-color: var(--green);
}

/* Shake Animation */
@keyframes shake {
  from {
    transform: translateY(-5%);
  }
  to {
    transform: translateY(5%);
  }
}
:where(.css-42nv3w).ant-picker-outlined {
  width: 100%;
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
}
:where(.css-42nv3w).ant-select-single {
  font-size: 14px;
  /* height: 48px; */
}

:where(.css-dev-only-do-not-override-42nv3w).ant-picker-outlined {
  width: 100%;
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
}
:where(.css-dev-only-do-not-override-42nv3w).ant-select-single {
  font-size: 14px;
  /* height: 48px; */
}

.ant-btn-card {
  margin-right: 9px;
  font-weight: 500;
  font-size: 9px;
  height: 25px;
  padding: 0px 12px;
  line-height: 18px;
}
.ant-btn-modal {
  font-weight: 500;
  font-size: 9px;
  height: 25px;
  padding: 0px 12px;
  line-height: 22px;
}
.ant-btn-form {
  font-weight: 500;
  font-size: 10px;
  height: 27px;
  padding: 0px 13px;
  line-height: 22px;
}
.ant-btn-confirm-cancel {
  font-weight: 500;
  font-size: 9px;
  height: 25px;
  padding: 0px 12px;
  line-height: 22px;
  background-color: red;
  color: white;

}
/* .ant-btn-confirm-cancel :hover{
  background: #ff7875 !important;
} */
:where(.css-42nv3w).ant-input-affix-wrapper >input.ant-input {
  margin-top: -9px;
  padding: 0px;
  font-size: inherit;
  border: none;
  border-radius: 0;
  outline: none;
  background: transparent;
  color: inherit;
}
:where(.css-dev-only-do-not-override-42nv3w).ant-input-affix-wrapper >input.ant-input {
  margin-top: -9px;
  padding: 0px;
  font-size: inherit;
  border: none;
  border-radius: 0;
  outline: none;
  background: transparent;
  color: inherit;
}
.progress-wrapper {
  font-size: .6em;
  width: 98%;
  color: #fff;
  margin: 2px 0%;
  text-align: center;
  background-color: #bbbbbb;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 5px;
}
.progress-bar {
  color: black;
  padding: 4px;
  min-width: 20%;
  white-space: nowrap;
  border-radius: 5px;
  background-color: #91caff;
  animation: progressAnimation 2s linear;
}

@keyframes progressAnimation {
  from {
    width: 0;
    background-color: #91caff;
  }
  to {
    width: 100%;
    background-color: #32CD32; /* Color for completed progress */
  }
}
.ant-tabs-tabpane {
  animation: fadeInUp 1.5s ease; /* Example animation */
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
:where(.css-42nv3w).ant-select-single {
  font-size: 14px;
  /* height: 48px; */
  width: 100%;
}
:where(.css-dev-only-do-not-override-42nv3w).ant-select-single {
  font-size: 14px;
  /* height: 48px; */
  width: 100%;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item a, .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu a {
  padding: 8px 12px;
  color: white;
  border-radius: 24px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  box-shadow: none;
}
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item a:hover, .layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu a:hover {
  color: #141414;
  background: darkcyan;
  
  
}
:where(.css-42nv3w).ant-input-affix-wrapper >input.ant-input {
  margin-top: -1px;
  padding: 0px;
  font-size: inherit;
  border: none;
  border-radius: 0;
  outline: none;
  background: transparent;
  color: inherit;
}
:where(.css-dev-only-do-not-override-42nv3w).ant-input-affix-wrapper >input.ant-input {
  margin-top: -1px;
  padding: 0px;
  font-size: inherit;
  border: none;
  border-radius: 0;
  outline: none;
  background: transparent;
  color: inherit;
}

.current-month {
  text-align: center;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: bold;
}
.calendar-container {
  display: flex;
  justify-content: center;
}
.horizontal-calendar {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  justify-content: center;
}
 
.day {
  flex: 0 0 auto;
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #91caff;
  scroll-snap-align: center;
  cursor: pointer;
  border-radius: 50%;
  margin: 0 2px;
  font-weight: bold;
}
 
.selected {
  background-color: #1890ff;
  color:#FFF;
  border: 1.5px solid #fff;
}
.custom-modal .ant-modal-content {
  max-width: 100% !important;
  min-height: 389px !important;
}
.fixed-footer{
  bottom: 0;
  position: fixed;
  margin: 0;
  width: 100%;
}
.layout-dashboard .ant-layout-footer {
     margin: 0px !important;
}
.pin-logo{
  width: 30%;
}
@media screen and (max-width: 480px) {
  .signin {
    background-color: cadetblue;
    width: 364px !important;
  }
  .pin-logo{
    width: 100%;
  }
  #video-background{
    display: none;
  }
  .pin-field{
    width: 5.1rem;
  }
  .user-info{
    width: 20.1rem;
    padding: -1px 10px 10px 48px;
  }
  .vacation-info{
    width: 32.1rem;
    margin-left: -5rem;
  }
  .manualEntry-info{
    width: 32.1rem;
    margin-left: -5rem;
  }
  .multiScheduler-info{
    width: 32.1rem;
    margin-left: -5rem;
  }
}


/* 
@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;	
	font-family: Raleway, sans-serif;
} */

.error{
  color: darksalmon;
}
.sign_container {
  /* background: linear-gradient(90deg, #C7C5F4, #776BCC); */
  transition: background-color 4s ease; 
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
}

.sign_screen {		
	background: linear-gradient(90deg, #5D54A4, #7C78B8);		
	position: relative;	
	height: 600px;
	width: 360px;	
	box-shadow: 0px 0px 24px #5C5696;
}

.screen__content {
	z-index: 1;
	position: relative;	
	height: 100%;
}

.screen__background {		
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0);	
}

.screen__background__shape {
	transform: rotate(45deg);
	position: absolute;
}

.screen__background__shape1 {
	height: 520px;
	width: 520px;
	background: #FFF;	
	top: -50px;
	right: 120px;	
	border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
	height: 220px;
	width: 220px;
	background: #6C63AC;	
	top: -172px;
	right: 0;	
	border-radius: 32px;
}

.screen__background__shape3 {
	height: 540px;
	width: 190px;
	background: linear-gradient(270deg, #5D54A4, #6A679E);
	top: -24px;
	right: 0;	
	border-radius: 32px;
}

.screen__background__shape4 {
	height: 400px;
	width: 200px;
	background: #7E7BB9;	
	top: 420px;
	right: 50px;	
	border-radius: 60px;
}

.login {
	width: 320px;
	padding: 30px;
	padding-top: 156px;
}

.login__field {
	padding: 20px 0px;	
	position: relative;	
}

.login__icon {
	position: absolute;
	top: 30px;
	color: #7875B5;
  /* font-size: 1.2em; */
}

.login__input {
	border: none;
	border-bottom: 2px solid #D1D1D4;
	background: none;
	padding: 10px;
	padding-left: 24px;
	font-weight: 700;
	width: 75%;
	transition: .2s;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
	outline: none;
	border-bottom-color: #6A679E;
}

.login__submit {
	background: #fff;
	font-size: 14px;
	margin-top: 30px;
	padding: 16px 20px;
	border-radius: 26px;
	border: 1px solid #D4D3E8;
	text-transform: uppercase;
	font-weight: 700;
	display: flex;
	align-items: center;
	width: 100%;
	color: #4C489D;
	box-shadow: 0px 2px 2px #5C5696;
	cursor: pointer;
	transition: .2s;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
	border-color: #6A679E;
	outline: none;
}

.button__icon {
	font-size: 24px;
	margin-left: auto;
	color: #7875B5;
}

.social-login {	
	position: absolute;
	height: 140px;
	width: 160px;
	text-align: center;
	bottom: 0px;
	right: 0px;
	color: #fff;
}

.social-icons {
	display: flex;
	align-items: center;
	justify-content: center;
}

.social-login__icon {
  font-size: 1.3em;
	padding: 20px 10px;
	color: #fff;
	text-decoration: none;	
	text-shadow: 0px 0px 8px #7875B5;
}

.social-login__icon:hover {
	transform: scale(1.5);	
}
.ant-tooltip{
  display: none !important;
}
.ant-upload-list-item-error{
  border-color: #32CD32 !important;
}
.ant-upload-list .ant-upload-list-item-error .ant-upload-list-item-name{
  color: #32CD32 !important;
}
:where(.css-dev-only-do-not-override-42nv3w).ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-list-item-actions .anticon, :where(.css-dev-only-do-not-override-42nv3w).ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-list-item-actions .anticon:hover {
  color: #32CD32 !important;
}